import React, {useState} from 'react'
 

export const Videoshow = (props) => {
 
 
	return (
		<div id='video'>
			<div className="row">
			 
				<i class="fa-solid fa-angles-down fs-1 mt-5 mb-5 text-center text-light" ></i> 
				 
			</div>
		</div>
		 
	);
 
  
}
